<template>
	<div>
		<!-- 弹窗-添加/修改用户 -->
		<el-dialog :title="form.id ? '修改题目' : '添加题目'" @close="cancel" :center="true" :visible.sync="dialogFormVisible"
			width="50%">
			<el-form ref="form" :model="form" :rules="formRules">
				<el-form-item label="题目标题:" :label-width="formLabelWidth" prop="topic_title">
					<el-input v-model="form.topic_title" autocomplete="off" placeholder="请输入标题标题" />
				</el-form-item>
				<el-form-item label="题目副标题:" :label-width="formLabelWidth" prop="topic_subText">
					<el-input v-model="form.topic_subText" autocomplete="off" placeholder="请输入题目副标题" />
				</el-form-item>
				<!-- <el-form-item label="题目插图:" :label-width="formLabelWidth" prop="topic_illustrations"> -->
				<!-- <span v-for="(item,index) in topic_illustrations" :key="index"></span> -->
				<!-- <el-image style="width: 140px; height: 140px"
						:src="form.topic_illustrations?form.topic_illustrations : $store.state.iphost+'/img/all/add.png'"
						@click="ceshifilekong" fit="contain"></el-image>
				</el-form-item> -->

				<el-form-item label="题目插图:" :label-width="formLabelWidth" prop="topic_illustrations">
					
					
				</el-form-item>
				<!-- 状态 -->
				<el-form-item label="题目的类型:" :label-width="formLabelWidth" prop="status">
					<el-radio-group v-model="form.topic_type">
						<el-radio :label="1">单选</el-radio>
						<el-radio :label="2">多选</el-radio>
					</el-radio-group>
				</el-form-item>

				<!-- 新增按钮 -->
				<el-row :gutter="10">
					<el-col :span="1.5">
						<el-button type="primary" plain icon="el-icon-plus" size="mini"
							@click="handleMember">新增</el-button>
					</el-col>
				</el-row>
				<el-table v-loading="loading" :lazy="true" :data="form.topic_questionOptList" style="width: 100%"
					:header-cell-style="{ textAlign: 'center' }" :cell-style="{ textAlign: 'center' }">
					<!-- <el-table-column prop="id" sortable label="id" /> -->
					<el-table-column prop="optSeq" label="选择项" />
					<el-table-column prop="optContent" label="选项内容" />
					<el-table-column prop="score" label="比分" />
					<el-table-column prop="redirectSerialNum" label="计分值" />
					<el-table-column prop="answerSeq" label="答案" />
					<el-table-column label="操作" width="150" fixed="right">
						<template slot-scope="scope">
							<el-button size="mini" type="success" @click="handleMember(scope.row,scope)">修改</el-button>
							<el-popconfirm style="margin-left: 10px;" confirm-button-text='确定' cancel-button-text='不用了'
								icon="el-icon-info" icon-color="red" title="确定要删除吗？" @confirm="handleDelete(scope)">
								<el-button size="mini" type="danger" slot="reference">删除</el-button>
							</el-popconfirm>
							<!-- <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button> -->
						</template>
					</el-table-column>
				</el-table>
				<!-- </el-form-item> -->
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="danger" @click="cancel">取消</el-button>
				<el-button type="primary" @click="confirm">确定</el-button>
			</div>
		</el-dialog>

		<!-- 右侧弹出内容 -->
		<el-drawer title="题目" :visible.sync="drawer_start" size="50%" direction="rtl" :before-close="handleClose">
			<el-form ref="form" :model="drawerdata" :rules="formRules">
				<el-form-item label="选择项:" :label-width="formLabelWidth" prop="optSeq">
					<el-input v-model="drawerdata.optSeq" autocomplete="off" placeholder="请输入选择项" />
				</el-form-item>
				<el-form-item label="选项内容:" :label-width="formLabelWidth" prop="optContent">
					<el-input v-model="drawerdata.optContent" autocomplete="off" placeholder="请输入选项内容" />
				</el-form-item>
				<el-form-item label="比分:" :label-width="formLabelWidth" prop="score">
					<el-input v-model="drawerdata.score" autocomplete="off" placeholder="请输入比分" />
				</el-form-item>
				<el-form-item label="计分值:" :label-width="formLabelWidth" prop="redirectSerialNum">
					<el-input v-model="drawerdata.redirectSerialNum" autocomplete="off" placeholder="请输入计分值" />
				</el-form-item>
				<el-form-item label="答案:" :label-width="formLabelWidth" prop="answerSeq">
					<el-input v-model="drawerdata.answerSeq" autocomplete="off" placeholder="请输入答案" />
				</el-form-item>

			</el-form>
			<div class="dialog-footer">
				<el-button type="primary" @click="confirm_drawer">确定</el-button>
			</div>
		</el-drawer>

		<!-- {{aa}} -->
		<!-- <button @click="democe">测试</button> -->
	</div>
</template>

<script>
	const {
		add_up_topic,
	} = require("@/api/interest_topic");
	export default {
		name: 'edittopic',
		props: {
			// 是否显示文件空间
			datapro: {
				type: Object,
				default: {
					topic_illustrations: [],
					topic_questionOptList: [],
					topic_subText: '',
					topic_title: '',
					topic_type: 1
				}
			},
		},
		computed: {
			form: function() {
				return this.datapro;
			}
		},
		data() {
			return {
				scopeindex: null,
				drawer_start: false,
				drawerdata: {},
				dialogFormVisible: true,
				loading: false,
				// form 表单的lable属性 宽度
				formLabelWidth: '110px',
				// 表单校验
				formRules: {
					topic_illustrations: [{
						required: true,
						message: '没有封面~~',
						trigger: 'blur'
					}],
					classify_remark: [{
						required: true,
						message: '请输入描述',
						trigger: 'blur'
					}],
					classify_title: [{
						required: true,
						message: '标题不能为空',
						trigger: 'blur'
					}]
				},
			}
		},
		methods: {

			handleDelete(scope) {
				let index = scope.$index;

				this.form.topic_questionOptList.splice(index, 1)


			},


			// 确定按钮
			confirm_drawer() {
				if (this.scopeindex || this.scopeindex === 0) {
					this.form.topic_questionOptList[this.scopeindex] = this.drawerdata
				} else {
					this.form.topic_questionOptList.push(this.drawerdata)
				}
				this.drawer_start = false;
			},

			// 打开选择
			handleMember(row, scope) {
				if (scope?.$index || scope?.$index == 0) {
					this.scopeindex = scope?.$index;
				}
				if (row) row = JSON.parse(JSON.stringify(row));
				this.drawerdata = row || {};
				this.drawer_start = true
			},

			// 关闭
			handleClose(done) {
				done();
				// this.$confirm('确认关闭？')
				// 	.then(_ => {

				// 	})
				// 	.catch(_ => {});


				this.scopeindex = null;
			},
			democe() {
				this.aa.id = 1111
			},
			// 弹窗里面的取消按钮
			cancel() {
				// 重置数据
				// this.form = {}
				// this.$nextTick(() => {
				// 	// 移除表单校验效果
				// 	this.$refs.form.clearValidate()
				// })
				this.$emit('cancel')
			},
			// 弹窗里面的确认按钮
			async confirm() {
				let formti = JSON.parse(JSON.stringify(this.form));
				formti.topic_questionOptList = JSON.stringify(formti.topic_questionOptList);
				formti.topic_illustrations = JSON.stringify(formti.topic_illustrations);
				let add_up_topicres = await add_up_topic({
					...formti
				});
				if (add_up_topicres.code == 1) {
					this.$message({
						type: 'success',
						message: '成功~~'
					})
					this.$emit('refulldata')
					this.dialogFormVisible = false;
				}
				// 表单校验
				// await this.$refs.form.validate();
				// // 调用 添加/修改 角色接口函数
				// await this.add_or_edit_role(this.form)
				// // 移除表单校验效果
				// this.$refs.form.clearValidate()

			},
		},
	}
</script>

<style lang="less" scoped>
	.wrap {
		margin: 20px;

		// 分页组件 距离上边的距离
		.el-pagination {
			margin-top: 20px;
		}
	}

	// 表头最外边框
	::v-deep .el-table__header {
		margin-top: 20px;
		border: 1px solid #ebeef5;
	}

	.dialog-footer {
		display: flex;
		justify-content: flex-start;
		margin-left: 110px;
	}
</style>